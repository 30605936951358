import { forum, questionBankUrl, survey } from "../../js/path";
import _ from "lodash";

export default {
  data() {
    return {
      preventClose: false,
      forums: [],
      pageNo: null,
      fields: [
        {
          key: "id",
          label: "ID",
          sortable: true,
        },
        {
          key: "title",
        },
        {
          key: "is_active",
          label: "Status",
        },
        {
          key: "question",
          label: "Add Question",
        },
        {
          key: "show_survey_question",
          label: "Survey Question List",
        },
        {
          key: "report",
        },
        {
          key: "download_excel",
          label: "Download Report",
        },
        {
          key: "edit",
        },
        {
          key: "delete",
        },
      ],
      params: "",
      form: {
        title: "",
        description: "",
        pass_text: "",
        pass_btn_text: "",
        fail_text: "",
        fail_btn_text: "",
        is_active: "",
        is_password: "",
        survey_password: "",
        allowed_members_from: "",
        google_form_link: "",
        partner_division_id: "",
        html_content: "",
        slug: "",
        // questions: [
        //   {
        //     id: null,
        //     mapped_id: "",
        //     question: "",
        //     show_answer: 0,
        //     show_answer_details: 0,
        //   },
        // ],
      },
      question_csv: "",
      download_question: "",
      allowedMemberFrom: [
        {
          value: "all",
          text: "All",
        },
        {
          value: "csv",
          text: "CSV",
        },
      ],
      filter: null,
      tableData: [],
      sortBy: "id",
      sortDesc: true,
      filterOn: [],
      key: 0,
      currentPage: 1,
      activeTab: "all",
      image_name: "",
      pass_image: "",
      fail_image: "",
      member_csv_file: "",
      download_member_csv: "",
      question_csv_file: "",
      image_name_url: "",
      pass_image_url: "",
      fail_image_url: "",
      edit: {
        image_name_url: null,
        pass_image_url: null,
        fail_image_url: null,
      },
      questionName: [],
      surveyId: "",
      questionReport: "",
    };
  },
  methods: {
    slugify() {
      const title = this.form.title;
      if (title) {
        const slug_generate = title
          .toString()
          .trim()
          .toLowerCase()
          .replace(/\s+/g, "-")
          .replace(/[^\w-]+/g, "")
          .replace(/--+/g, "-")
          .replace(/^-+/, "")
          .replace(/-+$/, "");
        this.form.slug = slug_generate;
      } else {
        this.form.slug = null;
      }
    },
    async downloadReport(id) {
      this.$store.commit("loader/updateStatus", true);
      try {
        const url = `${survey.downloadReport}/${id}`;
        const res = await this.getRequest(url);
        if (res) {
          this.questionReport = res?.survey_report_file ?? "";
          if (this.questionReport) {
            window.location.href = this.questionReport;
          }
        }
      } catch (err) {
        this.$store.commit("toast/updateStatus", {
          status: true,
          icon: "error",
          title: "Please add question file",
        });
      }
      this.$store.commit("loader/updateStatus", false);
    },
    beforeModalHide(event) {
      if (this.preventClose) {
        event.preventDefault();
      }
    },
    async fetchSingleForum(id) {
      const url = forum.forumUrl;
      const data = await this.getRequest(url + "/" + id);
      if (data.status) {
        this.form.partner_division_id = data.response;
      }
    },
    downloadCSV() {
      window.location.href = this.download_member_csv;
    },
    showModal(id) {
      this.question_csv_file = "";
      this.surveyId = id;
      this.$root.$emit("bv::show::modal", "questionModal", "#btnShow");
    },
    async handleQuestionSubmit() {
      if (this.question_csv_file == "") {
        this.$store.commit("toast/updateStatus", {
          status: true,
          icon: "error",
          title: "Please add question file",
        });
        this.preventClose = true;
        return false;
      }
      this.$store.commit("loader/updateStatus", true);
      try {
        let formData = new FormData();
        if (this.question_csv_file) {
          formData.append("questions_csv_file", this.question_csv_file);
        }
        let url = `${survey.addQuestionCSV}/${this.surveyId}`;
        const res = await this.postRequest(url, formData);
        if (res.status) {
          this.$store.commit("toast/updateStatus", {
            status: true,
            icon: "success",
            title: "File uploaded successfully",
          });
          this.closeQuestionModal();
        }
      } catch (err) {
        this.$store.commit({
          status: true,
          icon: "error",
          title: "Something went wrong",
        });
      }
      this.$store.commit("loader/updateStatus", false);
    },
    closeQuestionModal() {
      this.preventClose = false;
      this.question_csv_file = "";
      this.$bvModal.hide("questionModal");
    },
    fetchQuestion: _.debounce(async function (query) {
      this.$store.commit("loader/updateStatus", false);
      this.questionName = [];
      if (query != "" && query != null) {
        this.$store.commit("loader/updateStatus", true);
        const url = questionBankUrl.questionBank;
        const data = await this.getRequest(url + "?search=" + query);
        if (data.status) {
          this.questionName = data.response.data;
        }
      }
      this.$store.commit("loader/updateStatus", false);
    }, 1000),
    addQuestion() {
      this.form.questions.push({
        mapped_id: null,
        id: "",
        show_answer: 0,
        show_answer_details: 0,
        question: "",
      });
    },
    removeQuestion(key) {
      if (key == 0) return;
      this.form.questions.splice(key, 1);
    },
    searchFor() {
      if (this.filter.length > 1) this.fetchData("search");
      else if (this.filter.length == 0) this.fetchData("search");
    },
    filterPage() {
      if (this.pageNo.length > 0) {
        this.params = `&page=${this.pageNo}`;
        this.fetchData(this.activeTab);
        this.currentPage = this.pageNo;
      }
    },
    search(event) {
      if (this.filter.length > 1) {
        if (event.keyCode == 13) {
          this.fetchData("search");
        }
      } else if (this.filter.length == 0) this.fetchData("search");
    },
    onFiltered(filteredItems) {
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    },
    async fetchData(pagination = null) {
      this.$store.commit("loader/updateStatus", true);
      try {
        let url = survey.getSurvey;
        if (pagination == "search") {
          url = survey.getSurvey + "?search=" + this.filter;
        } else if (pagination) {
          url = url + "?page=" + pagination;
        }
        const data = await this.getRequest(url);
        if (data.status) {
          const responseData = data.response;
          this.tableData = responseData;
        }
      } catch (err) {
        this.$store.commit("toast/updateStatus", {
          status: true,
          icon: "error",
          title: err.data ? err.data.message : "Please try again!",
        });
      }
      this.$store.commit("loader/updateStatus", false);
    },
    async fetchPage(id) {
      this.$store.commit("loader/updateStatus", true);
      try {
        let url = survey.getSurvey + "/" + id;
        const data = await this.getRequest(url);
        if (data.status) {
          const responseData = data.response;
          if (responseData.title) {
            this.form.title = responseData.title;
          }
          if (responseData.description) {
            this.form.description = responseData.description;
          }
          if (responseData.pass_text) {
            this.form.pass_text = responseData.pass_text;
          }
          if (responseData.pass_btn_text) {
            this.form.pass_btn_text = responseData.pass_btn_text;
          }
          if (responseData.fail_text) {
            this.form.fail_text = responseData.fail_text;
          }
          if (responseData.fail_btn_text) {
            this.form.fail_btn_text = responseData.fail_btn_text;
          }
          if (responseData.allowed_members_from) {
            this.form.allowed_members_from = responseData.allowed_members_from;
          }
          if (responseData.google_form_link) {
            this.form.google_form_link = responseData.google_form_link;
          }
          if (responseData.survey_password) {
            this.form.survey_password = responseData.survey_password;
          }
          if (responseData.is_active) {
            this.form.is_active = true;
          }
          if (responseData.is_password) {
            this.form.is_password = true;
          }
          if (responseData.thumbnail_image) {
            this.edit.image_name_url = responseData.thumbnail_image;
          }
          if (responseData.pass_image) {
            this.edit.pass_image_url = responseData.pass_image;
          }
          if (responseData.fail_image) {
            this.edit.fail_image_url = responseData.fail_image;
          }
          if (responseData.members_csv_file) {
            this.member_csv_file = responseData.member_csv_file;
            this.download_member_csv = responseData.members_csv_file;
          }
          if (responseData.partner_division_id) {
            this.form.partner_division_id = this.fetchSingleForum(
              responseData.partner_division_id
            );
          }
          this.form.html_content = responseData?.html_content ?? "";
          this.form.slug = responseData?.slug ?? "";
        }
      } catch (err) {
        this.$store.commit("toast/updateStatus", {
          status: true,
          icon: "error",
          title: err.data ? err.data.message : "Please try again!",
        });
      }
      this.$store.commit("loader/updateStatus", false);
    },
    async deleteSurvey(id) {
      this.$store.commit("loader/updateStatus", true);
      try {
        let index = this.tableData.data.findIndex((e) => e.id === id);
        const url = survey.getSurvey + "/" + id;
        const data = await this.postRequest(url, {
          _method: "DELETE",
        });
        if (data.status) {
          this.tableData.data.splice(index, 1);
          this.$store.commit("toast/updateStatus", {
            status: true,
            icon: "success",
            title: data.message,
          });
        }
      } catch (err) {
        this.$store.commit("toast/updateStatus", {
          status: true,
          icon: "error",
          title: err.data ? err.data.message : "Please try again!",
        });
      }
      this.$store.commit("loader/updateStatus", false);
    },
    async updateStatus(id) {
      this.$store.commit("loader/updateStatus", true);
      try {
        let index = this.tableData.data.findIndex((e) => e.id === id);
        const url = survey.statusUpdate + "/" + id;
        const data = await this.postRequest(url, {
          is_active: !this.tableData.data[index].is_active,
        });
        if (data.status) {
          const responseData = data.response;
          this.tableData.data[index] = responseData;
          this.$store.commit("toast/updateStatus", {
            status: true,
            icon: "success",
            title: data.message,
          });
        }
        this.key += 1;
      } catch (err) {
        this.$store.commit("toast/updateStatus", {
          status: true,
          icon: "error",
          title: err.data ? err.data.message : "Please try again!",
        });
      }
      this.$store.commit("loader/updateStatus", false);
    },
    readFile(e, txt) {
      if (txt == "image_name") {
        this.image_name = e.target.files[0];
        if (!this.image_name) {
          this.$store.commit("toast/updateStatus", {
            status: true,
            icon: "error",
            title: "No File Choosen",
          });
          return false;
        }
        if (this.image_name.size > 2 * 1000 * 1000) {
          this.$store.commit("toast/updateStatus", {
            status: true,
            icon: "error",
            title: "Uploaded File is More than 2MB",
          });
          this.$refs.image_name.reset();
          this.image_name_url = "";
          this.edit.image_name_url = "";
          this.image_name = "";
          return false;
        } else {
          this.image_name_url = URL.createObjectURL(this.image_name);
          this.edit.image_name_url = "";
        }
        return true;
      } else if (txt == "pass_image") {
        this.pass_image = e.target.files[0];
        if (!this.pass_image) {
          this.$store.commit("toast/updateStatus", {
            status: true,
            icon: "error",
            title: "No File Choosen",
          });
          return false;
        }
        if (this.pass_image.size > 2 * 1000 * 1000) {
          this.$store.commit("toast/updateStatus", {
            status: true,
            icon: "error",
            title: "Uploaded File is More than 2MB",
          });
          this.$refs.pass_image.reset();
          this.pass_image_url = "";
          this.edit.pass_image_url = "";
          this.pass_image = "";
          return false;
        } else {
          this.pass_image_url = URL.createObjectURL(this.pass_image);
          this.edit.pass_image_url = "";
        }
        return true;
      } else if (txt == "fail_image") {
        this.fail_image = e.target.files[0];
        if (!this.fail_image) {
          this.$store.commit("toast/updateStatus", {
            status: true,
            icon: "error",
            title: "No File Choosen",
          });
          return false;
        }
        if (this.fail_image.size > 2 * 1000 * 1000) {
          this.$store.commit("toast/updateStatus", {
            status: true,
            icon: "error",
            title: "Uploaded File is More than 2MB",
          });
          this.$refs.fail_image.reset();
          this.fail_image_url = "";
          this.edit.fail_image_url = "";
          this.fail_image = "";
          return false;
        } else {
          this.fail_image_url = URL.createObjectURL(this.fail_image);
          this.edit.fail_image_url = "";
        }
        return true;
      } else if (txt == "member_csv_file") {
        const file = e.target.files[0];
        const validExtensions = ["csv", "xlsx"];
        const fileExtension = file.name.split(".").pop().toLowerCase();
        if (validExtensions.includes(fileExtension)) {
          this.member_csv_file = file;
        } else {
          this.member_csv_file = "";
          this.$refs.member_csv_file.reset();
          this.$store.commit("toast/updateStatus", {
            status: true,
            icon: "error",
            title: ".csv and .xlsx files allowed",
          });
        }
      } else if (txt == "question_csv_file") {
        const file = e.target.files[0];
        const validExtensions = ["csv", "xlsx"];
        const fileExtension = file.name.split(".").pop().toLowerCase();
        if (validExtensions.includes(fileExtension)) {
          this.question_csv_file = file;
        } else {
          this.question_csv_file = "";
          this.$refs.question_csv_file.reset();
          this.$store.commit("toast/updateStatus", {
            status: true,
            icon: "error",
            title: ".csv and .xlsx files allowed",
          });
        }
      }
    },
    async submitData() {
      try {
        this.submitted = true;
        this.$v.$touch();
        if (this.$v.$invalid) {
          this.$store.commit("toast/updateStatus", {
            status: true,
            icon: "error",
            title: "Please Fill The Required Fields",
          });
          return false;
        }
        if (
          this.form.allowed_members_from == "csv" &&
          this.member_csv_file == ""
        ) {
          this.$store.commit("toast/updateStatus", {
            status: true,
            icon: "error",
            title: "Member CSV file is required",
          });
          return false;
        }
        this.$store.commit("loader/updateStatus", true);
        let url = survey.getSurvey;
        if (this.$route.name == "edit-survey") {
          url = survey.getSurvey + "/" + this.$route.params.id;
        }
        let dataAppend = new FormData();
        if (this.image_name) {
          dataAppend.append("thumbnail_image", this.image_name);
        }
        if (this.pass_image_url) {
          dataAppend.append("pass_image", this.pass_image);
        }
        if (this.fail_image_url) {
          dataAppend.append("fail_image", this.fail_image);
        }
        if (this.form.allowed_members_from == "csv") {
          if (this.member_csv_file) {
            dataAppend.append("members_csv_file", this.member_csv_file);
          }
        }
        if (this.form.partner_division_id) {
          dataAppend.append(
            "partner_division_id",
            this.form.partner_division_id.id
          );
        }
        // let questions = [];
        // this.form.questions.forEach((c) => {
        //   let tempQuestion = {
        //     id: c.id.id,
        //     question: c.id.question,
        //     mapped_id: c.mapped_id,
        //     show_answer: c.show_answer,
        //     show_answer_details: c.show_answer_details,
        //   };
        //   questions.push(tempQuestion);
        // });
        // if (this.form.questions && questions) {
        //   dataAppend.append("questions", JSON.stringify(questions));
        // }
        if (this.form.is_active) {
          dataAppend.append("is_active", 1);
        } else {
          dataAppend.append("is_active", 0);
        }
        if (this.form.is_password) {
          dataAppend.append("is_password", 1);
        } else {
          dataAppend.append("is_password", 0);
        }
        for (var key in this.form) {
          if (
            key != "thumbnail_image" &&
            key != "members_csv_file" &&
            key != "partner_division_id" &&
            key != "pass_image" &&
            key != "fail_image" &&
            key != "is_active" &&
            key != "is_password"
            // && key != "questions"
          ) {
            dataAppend.append(key, this.form[key]);
          }
        }
        if (this.$route.name == "edit-survey") {
          dataAppend.append("_method", "put");
        }
        const data = await this.postRequest(url, dataAppend);
        if (data.status) {
          this.$store.commit("toast/updateStatus", {
            status: true,
            icon: "success",
            title: data.message,
          });
          this.$router.push("/survey");
        }
      } catch (err) {
        console.log(err);
        this.$store.commit("toast/updateStatus", {
          status: true,
          icon: "error",
          title: err.data ? err.data.message : "Please try again!",
        });
      }
      this.$store.commit("loader/updateStatus", false);
    },
    fetchForum: _.debounce(async function (query) {
      this.$store.commit("loader/updateStatus", true);
      this.forums = [];
      if (query != "" && query != null) {
        const url = forum.fetchAllActiveForum;
        const data = await this.getRequest(url + "&search=" + query);
        if (data.status) {
          this.forums = data.response.data;
        }
      }
      this.$store.commit("loader/updateStatus", false);
    }, 1000),
  },
  watch: {
    currentPage: {
      handler: function (value) {
        this.params = `page=${value}`;
        this.fetchData(value);
        this.pageNo = this.currentPage;
      },
    },
  },
};
